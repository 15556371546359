
































































































































































































import {
    apiGetCustomerList,
    apiSetCustomerStatus,
    apiCustomerDelete,
    apiGetappListsNopage,
    apiGetBusinessAppsIndex,
    apiSaveBusinessAppsIndex,
    apiBusinessAccountAdd,
    apiGetAppList,
} from "@/api/shop";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging, debounce } from "@/utils/util";

type toUptype = {
    business_id: number | null;
    all_balance: number | null;
    msg: string;
};

@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
    },
})
export default class CustomerList extends Vue {
    apiGetCustomerList = apiGetCustomerList;

    pager: RequestPaging = new RequestPaging();

    searchObj: Object = {
        bsn: "",
        company_name: "",
        create_time: "",
        status: "",
        type: 0,
    };

    toUpData: toUptype = {
        business_id: null, //客户id
        all_balance: null, //金额
        msg: "", //备注
    };

    //应用权限
    dialogVisible: boolean = false;

    //多选框
    checkList: any[] = [];

    //应用列表
    applicationList: any[] = [];

    //id
    id: number = 0;

    //充值弹窗
    topUpVisible: boolean = false;

    appList: any = [];

    getCustomerList(page?: number): void {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiGetCustomerList,
            params: {
                ...this.searchObj,
            },
        });
    }

    // 去编辑商城
    toCustomerEdit(id: number | any): void {
        this.$router.push({
            path: "/shop/customer_detail",
            query: {
                id: id,
            },
        });
    }

    toPage(id: number | any, type: string): void {
        if (type === "shop") {
            this.$router.push({
                path: "/mall/mall_list",
                query: {
                    business_id: id,
                },
            });
        }
        if (type === "supplier") {
            this.$router.push({
                path: "/supplier/supplier_list",
                query: {
                    business_id: id,
                },
            });
        }
    }

    // 去员工管理
    toStaff(row: any): void {
        this.$router.push({
            path: "/shop/staff_management",
            query: {
                id: row.id,
                company_name: row.company_name,
                // name: company_name,
            },
        });
    }
    // 修改商城状态
    async customerSwitchStatusEdit(status: number, id: number): Promise<void> {
        await apiSetCustomerStatus({ id });
        this.getCustomerList();
    }

    // 删除商城
    async onCustomerDelete(row: any): Promise<void> {
        await apiCustomerDelete({ id: row.id });
        this.getCustomerList();
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getCustomerList();
    }

    //打开用户权限弹窗
    async dialogOpen(id: number) {
        this.dialogVisible = true;
        this.id = id;
        const res: any = await apiGetBusinessAppsIndex({ id: id });
        this.checkList = res.map(Number);
    }

    //获取应用权限列表
    async getApplicationList() {
        const res: any = await apiGetappListsNopage({});
        this.applicationList = res;
    }

    async applicationSave() {
        const data = {
            id: this.id,
            apps_array: this.checkList,
        };

        const res: any = await apiSaveBusinessAppsIndex({ ...data }).finally(
            () => {
                this.dialogVisible = false;
            }
        );
    }

    async apiGetAppList() {
        await apiGetAppList({}).then((res: any) => {
            this.appList = res;
        });
    }

    //充值页面
    toBusinessAccountAdd(id: number) {
        this.toUpData.business_id = id;
        this.topUpVisible = true;
    }

    async businessAccountAdd() {
        if (!this.toUpData.all_balance) {
            this.$message({
                message: "请输入金额",
                type: "warning",
            });
            return;
        }

        if (!this.toUpData.msg) {
            this.$message({
                message: "请输入备注",
                type: "warning",
            });
            return;
        }
        const res = await apiBusinessAccountAdd({ ...this.toUpData });
        if (res) {
            this.topUpVisible = false;
            this.getCustomerList();
            Object.keys(this.toUpData).map((key) => {
                this.$set(this.toUpData, key, "");
            });
        }
    }

    handleClose() {
        Object.keys(this.toUpData).map((key) => {
            this.$set(this.toUpData, key, "");
        });

        this.topUpVisible = false;
    }

    /** S ods **/
    created() {
        this.businessAccountAdd = debounce(this.businessAccountAdd, 300);
        this.getCustomerList();
        this.getApplicationList();
        this.apiGetAppList();
    }
}
